(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-fraction/assets/utils/is-fraction.js') >= 0) return;  svs.modules.push('/components/payment/create-fraction/assets/utils/is-fraction.js');

'use strict';

const {
  Fraction
} = svs.components.payment.createFraction;
const isFraction = item => item instanceof Fraction;
setGlobal('svs.components.payment.createFraction.utils.isFraction', isFraction);

 })(window);