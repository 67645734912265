(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/payment/create-fraction/assets/create-fraction.js') >= 0) return;  svs.modules.push('/components/payment/create-fraction/assets/create-fraction.js');
'use strict';

function _classPrivateFieldInitSpec(e, t, a) { _checkPrivateRedeclaration(e, t), t.set(e, a); }
function _checkPrivateRedeclaration(e, t) { if (t.has(e)) throw new TypeError("Cannot initialize the same private elements twice on an object"); }
function _classPrivateFieldGet(s, a) { return s.get(_assertClassBrand(s, a)); }
function _classPrivateFieldSet(s, a, r) { return s.set(_assertClassBrand(s, a), r), r; }
function _assertClassBrand(e, t, n) { if ("function" == typeof e ? e === t : e.has(t)) return arguments.length < 3 ? t : n; throw new TypeError("Private element is not present on this object"); }
const {
  Payable,
  constants: {
    PayableType
  }
} = svs.components.payment.common;
var _activatedDrawId = new WeakMap();
var _containerId = new WeakMap();
var _groupId = new WeakMap();
var _price = new WeakMap();
var _productId = new WeakMap();
class Fraction extends Payable {
  constructor() {
    super(...arguments);
    _classPrivateFieldInitSpec(this, _activatedDrawId, void 0);
    _classPrivateFieldInitSpec(this, _containerId, void 0);
    _classPrivateFieldInitSpec(this, _groupId, void 0);
    _classPrivateFieldInitSpec(this, _price, void 0);
    _classPrivateFieldInitSpec(this, _productId, void 0);
  }
  setActivatedDrawId(activatedDrawId) {
    _classPrivateFieldSet(_activatedDrawId, this, activatedDrawId);
    return this;
  }

  get activatedDrawId() {
    return _classPrivateFieldGet(_activatedDrawId, this);
  }

  setContainerId(containerId) {
    _classPrivateFieldSet(_containerId, this, containerId);
    return this;
  }

  get containerId() {
    return _classPrivateFieldGet(_containerId, this);
  }

  setGroupId(groupId) {
    _classPrivateFieldSet(_groupId, this, groupId);
    return this;
  }

  get groupId() {
    return _classPrivateFieldGet(_groupId, this);
  }

  setPrice(price) {
    _classPrivateFieldSet(_price, this, price);
    return this;
  }

  get price() {
    return _classPrivateFieldGet(_price, this);
  }

  setProductId(productId) {
    _classPrivateFieldSet(_productId, this, productId);
    return this;
  }

  get productId() {
    return _classPrivateFieldGet(_productId, this);
  }

  get type() {
    return PayableType.FRACTION;
  }

  toJSON() {}

  toString() {}
}

const createFraction = () => new Fraction();
setGlobal('svs.components.payment.createFraction.createFraction', createFraction);
setGlobal('svs.components.payment.createFraction.Fraction', Fraction);

 })(window);